import "./services.css";

const Services = () => {
  return (
    <section id="services">
      <h2 id="works__title">Servicios</h2>
      <div id="works__articles-container">
        <aside>
          <details className="works_detalles">
            <summary>Protección Catódica</summary>
            <p>
              Realizamos relevamientos de sistemas de protección catódica para
              determinar la eficiencia de estos. Para los ensayos y estudio
              utilizamos la normativa vigente y profesionales con experiencia
              certificada. Alguno de los ensayos que realizamos son:
              <ul>
                <li>Relevamiento de Potenciales Naturales y On / OFF</li>
                <li>C.I.P.S. / D.C.V.G. / ECDA.</li>
                <li>Resistividad de Terreno y PH.</li>
                <li>Chequeo de Aislación de Juntas Dieléctricas.</li>
                <li>Mediciones de Puesta a Tierra.</li>
              </ul>
            </p>
          </details>
          <details className="works_detalles">
            <summary>Inspecciones de pruebas no destructivas</summary>
            <p>
              Ofrecemos una amplia varidas de Pruebas No Destructivas (PND). Las
              mismas tienen el propósito de evaluar el estado de los componentes
              a ensayar, recipientes, tuberías, soldaduras, etc. Nosotros
              ofrecemos la selección del ensayo que nuestros especialistas
              consideran más acorde para la detección de los defectos que los
              mismos puedan ser tanto de fabricación como de servicio, que
              puedan afectar su funcionamiento o comprometer la integridad
              mecánica del componente estudiado Los ensayos que ofrecemos a
              nuestros clientes son:
              <ul>
                <li>Ultrasonido</li>
                <li>Partículas Magnéticas</li>
                <li>Líquidos Penetrantes</li>
                <li>Phased Array </li>
                <li>TOFD</li>
                <li>Radiografía convencional y digital</li>
                <li>Identificación Positiva del Material</li>
                <li>Videoscopía </li>
                <li>Eddy Current</li>
              </ul>
            </p>
          </details>
          <details className="works_detalles">
            <summary>Calificación de Soldadores</summary>
            <p>
              La calificación de los soldadores busca incrementar las
              posibilidades de obtener soldaduras satisfactorias en la
              industria. El objetivo de la evaluación de los soldadores es
              evaluar la destreza y habilidad de un individuo en la creación de
              soldaduras de calidad, siguiendo un procedimiento de soldadura
              previamente aprobado (WPS).
              <ul>
                <li>Calificación de soldadores bajo el esquema API 1104</li>
                <li> Calificación de soldadores bajo el esquema AWS</li>
                <li>
                  Calificación de soldadores bajo el esquema ASME SECCION IX
                </li>
              </ul>
            </p>
          </details>
          <details className="works_detalles">
            <summary>Estudios de riesgo</summary>
            <p>
              Este estudio que permite evaluar los peligros potenciales y sus
              posibles consecuencias en un ducto existente o que el mismo este
              en la fase de proyecto, con el objeto de establecer medidas de
              prevención y de protección. El análisis de riesgo
              semi-cuantitativo examina de forma individual cada una de las
              posibles amenazas, siguiendo los criterios establecidos por los
              estándares ASME B31.8S y API 1160. Esta aproximación brinda al
              operador una comprensión precisa de cómo cada amenaza puede
              impactar su sistema, capacitándolo así para elaborar estrategias
              de mitigación particularmente adecuadas y eficaces
            </p>
          </details>
          <details className="works_detalles">
            <summary>Capacitaciones En construcción</summary>
            <p>
              Nuestras capacitaciones están diseñadas para proporcionar a los
              participantes las habilidades y el conocimiento necesarios para
              prosperar en este sector competitivo. Ya sea que seas un
              profesional experimentado buscando actualizar tus habilidades o
              alguien que está considerando una carrera en la construcción,
              nuestras capacitaciones pueden ayudarte a alcanzar tus metas.
            </p>
          </details>
          <details className="works_detalles">
            <summary>Inspeccion de obras del sector OIL & GAS</summary>
            <p>
              Nuestra organización ofrece servicios de inspección para proyectos
              de gas y petróleo en todo el país, respaldados por un equipo de
              profesionales altamente experimentados. Contamos con personal con
              experiencia en diversas especialidades relacionadas con la
              inspección y control de obras, materiales y fabricación en taller.
              Nuestros servicios también incluyen la detección y verificación de
              posibles anomalías en instalaciones, supervisión del desarrollo
              del proyecto, medición del progreso y la garantía de que se
              cumplan rigurosamente los estándares de Seguridad, Higiene y Medio
              Ambiente (SHyMA) y de calidad en cada etapa del proceso.
            </p>
          </details>
        </aside>
      </div>
    </section>
  );
};
export default Services;
