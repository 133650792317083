import "./App.css";
import Header from "./components/header/Header";
import AboutUs from "./components/aboutus/AboutUs";
import Form from "./components/form/Form";
import Obras from "./components/obras/Obras";
import Services from "./components/servicios/Services";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <Header />
      <Form />
      <AboutUs />
      <Services />
      <Obras />
      <Footer />
    </>
  );
}

export default App;
