import { FaRoad } from "react-icons/fa6";
import { MdOutlinePlace, MdElectricBolt, MdEngineering } from "react-icons/md";
import { IoWaterOutline } from "react-icons/io5";
import { GiAbstract055, GiGasMask } from "react-icons/gi";
import { FaTools } from "react-icons/fa";
import { PiEngineBold } from "react-icons/pi";
import { GrHostMaintenance } from "react-icons/gr";

import "./obras.css";

const Obras = () => {
  return (
    <section id="obras">
      <h2 id="services__title">Obras</h2>
      <div id="services__articles-container">
        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos apertura de las calles, que implica la excavación y
              nivelación del terreno para prepararlo para el asfaltado.
              Aumentando la seguridad al proporcionar una superficie de
              conducción clara y bien mantenida.
            </p>
          </div>
          <div className="services__article-wrapper">
            <FaRoad />
            <p>Apertura y asfaltado de calles ​</p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Con un equipo de más de 60 operadores telefónicos especializados y
              formados para atención y venta telefónica. Nuestros call center
              operan con las últimas tecnologías permitiendo ejecutar campañas y
              acciones específicas con excelente impacto y resultados.
            </p>
          </div>
          <div className="services__article-wrapper">
            <MdOutlinePlace />
            <p>Amojonamiento de loteo </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos redes eléctricas de media y baja tensión subterráneas.
              Nuestro servicio es integral e incluye desde el zanjeo, cableado y
              construcción de cámaras para transformadores.
            </p>
          </div>
          <div className="services__article-wrapper">
            <MdElectricBolt />

            <p>Redes de energía eléctrica </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos redes de agua y de desagües en emprendimientos de
              barrios cerrados y privados. Realizamos el proyecto y construcción
              de plantas depuradoras y hacemos reutilización terciaria para
              riego.
            </p>
          </div>
          <div className="services__article-wrapper">
            <IoWaterOutline />

            <p>Construcción red de agua interna </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos redes de agua y de desagües en emprendimientos de
              barrios cerrados y privados. Realizamos el proyecto y construcción
              de plantas depuradoras y hacemos reutilización terciaria para
              riego.
            </p>
          </div>
          <div className="services__article-wrapper">
            <GiAbstract055 />

            <p>Construcción de desagües y plantas de tratamiento </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos ramales de gasoductos de hasta 6” de diámetro, tenemos
              experiencia en reparaciones de ductos de hasta 22” de diámetro.
              Realizamos la obra de protección catódica, sea con ánodos de
              sacrificio como con corriente impresa.
            </p>
          </div>
          <div className="services__article-wrapper">
            <GiGasMask />
            <p>Construcción de ramales de gas </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos ramales de gasoductos de hasta 6” de diámetro, tenemos
              experiencia en reparaciones de ductos de hasta 22” de diámetro.
              Realizamos la obra de protección catódica, sea con ánodos de
              sacrificio como con corriente impresa.
            </p>
          </div>
          <div className="services__article-wrapper">
            <FaTools />
            <p>Reparación en gasoductos y acueductos </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Construimos naves industriales, construcciones temporales, garitas
              de entradas a complejos, edificaciones de Club House de grandes
              superficies y piscinas entre otras.
            </p>
          </div>
          <div className="services__article-wrapper">
            <MdEngineering />
            <p>Construcción civil </p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos ramales de gasoductos de hasta 6” de diámetro, tenemos
              experiencia en reparaciones de ductos de hasta 22” de diámetro.
              Realizamos la obra de protección catódica, sea con ánodos de
              sacrificio como con corriente impresa.
            </p>
          </div>
          <div className="services__article-wrapper">
            <PiEngineBold />
            <p>Construcción de obras para tuberías</p>
          </div>
        </article>

        <article className="services__article">
          <div className="article__div-hover">
            <p className="hover-text">
              Realizamos el Mantenimiento de Estaciones de Medición y Regulación
              de Gas en su totalidad, desde el mantenimiento del predio, pintura
              de las instalaciones y el plan de mantenimiento anual, incluido la
              lubricación de válvulas.
            </p>
          </div>
          <div className="services__article-wrapper">
            <GrHostMaintenance />
            <p>Mantenimiento de Estaciones de Medición y Gas.​</p>
          </div>
        </article>
      </div>
    </section>
  );
};
export default Obras;
