import "./aboutus.css";

const AboutUs = () => {
  return (
    <section id="aboutus">
      <div id="works__articles-container">
        <aside>
          <details open className="detalles">
            <summary>¿POR QUÉ RURTECH?</summary>
            <p>
              Somos un socio clave en su empresa, ponemos a su disposición
              nuestra experiencia de más de 15 años de trabajo continuo. <br />
              Trabajamos con los recursos y tiempos necesarios haciendo uso
              óptimo de este. Somos flexibles a sus necesidades, tiempos y
              presupuestos. <br /> Actualizamos nuestra tecnología de acuerdo
              con los tiempos y necesidades de los clientes. reducimos riesgos
              en el trabajo. <br /> Estas características son para enfocarnos en
              nuestros clientes y en nuestra totalidad nuestros valores de
              calidad, responsabilidad, diversidad, integridad y la pasión por
              todo lo que emprendemos son base fundacional de rurtech.
            </p>
          </details>
          <details className="detalles">
            <summary>¿QUIÉNES SOMOS?</summary>
            <p>
              Somos una empresa 100% argentina, nacida en la necesidad de dar
              servicios en emprendimientos de construcción de infraestructura de
              barrios privados y country. En cada crisis encontramos
              oportunidades y es de esa visión y ganas de ser mejores todos los
              días, comenzamos con apertura de calles y hoy realizamos,
              pavimentos, redes de gas, inspecciones, entre otras soluciones.
              <br />
              Nuestra identidad se caracteriza por nuestra transparencia somos
              un equipo íntegro, profesional y enfocados en el objetivo de
              nuestro cliente. perseguimos empáticamente, junto con nuestros
              clientes, la solución que necesiten. Estamos con ellos en el
              proceso y seremos parte de sus logros. Buscamos crecer a través de
              nuestra gente. Nuestro equipo comparte y se mimetiza con nuestras
              características, potenciando día a día su nivel de profesionalismo
              enfocados en crecer y los objetivos de la empresa.
            </p>
          </details>
        </aside>
      </div>
    </section>
  );
};

export default AboutUs;
