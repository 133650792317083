import "./footer.css";

import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

import logo from "../../images/logoRurtechDark.jpeg";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div id="footer__wrapper">
          <img id="footer__logo" src={logo} />
          <div id="footer__info-container">
            <ul id="footer__info-list">
              <li className="footer__info-container__li">
                Teléfono: 01170607429
              </li>
            </ul>
            <ul id="footer__icons-container">
              <li className="footer__icons-container-li">
                <a
                  target="_blank"
                  className="footer__icons-container-link"
                  href="mailto:info@rurtech.com.ar"
                >
                  <SiGmail className="footer__icon" />
                </a>
              </li>
              <li className="footer__icons-container-li">
                <a
                  target="_blank"
                  className="footer__icons-container-link"
                  href="tel:+01170607429"
                >
                  <FaWhatsapp className="footer__icon" />
                </a>
              </li>
              <li className="footer__icons-container-li">
                <a
                  target="_blank"
                  className="footer__icons-container-link"
                  href="tel:+01170607429"
                >
                  <FaPhoneAlt className="footer__icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="footer__copy">
          © Copyright 2024. All Rights Reserved by Rurtech
        </div>
      </footer>
    </>
  );
};

export default Footer;
